<template>
  <div>
    <div class="game-wrapper" v-if="game.id">
      <table>
        <tr>
          <td valign="top">
            <div id="gameboard" class="game-board"></div>
          </td>
          <td width="20px;">

          </td>
          <td valign="top" class="home">
            <table>
              <tr>
                <td class="card-img" style="line-height:0 !important;"><input style="width:250px;" type="image" src="/images/spelregels.jpg" data-toggle="modal" data-target="#rulesModal" /></td>
              </tr>
              <tr>
                <td>
                  <div @click="rollDice" class="dice" :class="diceClasses"></div>
                  <button class="btn btn-success btn-block" :disabled="game.dice.dice.rolling" @click="rollDice">{{ game.dice.dice.rolling ? 'ROLLING!' : 'Roll Dice' }}</button>
                </td>
              </tr>
              <tr>
                <td class="card-img">
                  <img class="hand" src="/images/green-card.jpg" @click="showQuestion('green')">
                </td>
              </tr>
              <tr>
                <td class="card-img">
                  <img class="hand" src="/images/orange-card.jpg" @click="showQuestion('orange')">
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td colspan="3" class="text-center pt-4">
            <div>
              <input @click="reset" type="image" src="/images/reset.jpg" style="width:200px;" alt="Reset">
            </div>
          </td>
        </tr>
      </table>
      <div v-draggable="pawns.black.draggableValue">
        <img id="black" class="pawn" src="/images/pawn-black.png">
      </div>
      <div v-draggable="pawns.red.draggableValue">
        <img id="red" class="pawn" src="/images/pawn-red.png">
      </div>
      <div v-draggable="pawns.green.draggableValue">
        <img id="green" class="pawn" src="/images/pawn-green.png">
      </div>
      <div v-draggable="pawns.blue.draggableValue">
        <img id="blue" class="pawn" src="/images/pawn-blue.png">
      </div>
      <div v-draggable="pawns.white.draggableValue">
        <img id="white" class="pawn" src="/images/pawn-white.png">
      </div>
      <div v-draggable="pawns.yellow.draggableValue">
        <img id="yellow" class="pawn" src="/images/pawn-yellow.png">
      </div>
    </div>
    <div class="modal fade" id="greenModal" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="greenModalLabel" v-if="game.questions.green.current">{{ game.questions.green.current }} <small>/{{ game.questions.green.last }}</small></h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-center">
            <img v-if="game.questions.green.current" :src="`/images/questions/${game.type}/${game.questions.green.current}.jpg`" />
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="orangeModal" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="orangeModalLabel" v-if="game.questions.orange.current">{{ game.questions.orange.current }}<small>/{{ game.questions.orange.last }}</small></h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-center">
            <img v-if="game.questions.orange.current" :src="`/images/questions/orange/${game.questions.orange.current}.jpg`" />
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="rulesModal" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="rulesModalLabel">Spelregel</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="text-center">
            <iframe width="100%" height="750" src="/images/spelregels.pdf"></iframe>
          </div>
        </div>
      </div>
    </div>
    <vue-confirm-dialog></vue-confirm-dialog>

  </div>
</template>

<script>
  import { Draggable } from 'draggable-vue-directive'

  export default {
    directives: {
      Draggable,
    },
    props: {
      initialGame: {
        type: Object,
        required: true
      },
      link: {
        type: String,
        required: true
      }
    },
    mounted() {
      this.hydrateGame(this.initialGame)
      window.Echo.channel('channel.' + this.game.id)
        .listen('GameUpdated', (e) => {
          this.hydrateGame(e.game)
        })

      self = this
      $('#greenModal').on('hide.bs.modal', function (e) {
        self.game.questions.green.current = null
        self.updateQuestions('green')
      })

      $('#orangeModal').on('hide.bs.modal', function (e) {
        self.game.questions.orange.current = null
        self.updateQuestions('orange')
      })
    },
    computed: {
      diceClasses() {
        return {
          'dice-1': this.game.dice.dice.value === 1,
          'dice-2': this.game.dice.dice.value === 2,
          'dice-3': this.game.dice.dice.value === 3,
          'dice-4': this.game.dice.dice.value === 4,
          'dice-5': this.game.dice.dice.value === 5,
          'dice-6': this.game.dice.dice.value === 6,
          'rolling': this.game.dice.dice.rolling
        }
      }
    },
    data () {
      return {
        copied: false,
        game: {
          id: null,
          type: null,
          updated: null,
          pawns:  {},
          dice: {
            value: 1,
            rolling: false
          },
          questions: {
            green: {
              current: null,
              next: 1,
              last: null,
            },
            orange: {
              current: null,
              next: 1,
              last: null,
            },
          }
        },
        pawns: {
          black: {
            draggableValue: {
              initialPosition: { left: 0, top:0 },
              onDragStart: this.onDragStart,
              onDragEnd: this.onDragEnd,
              resetInitialPos: false,
              stopDragging: false
            }
          },
          red: {
            draggableValue: {
              initialPosition: { left: 0, top:0 },
              onDragStart: this.onDragStart,
              onDragEnd: this.onDragEnd,
              resetInitialPos: false
            }
          },
          green: {
            draggableValue: {
              initialPosition: { left: 0, top:0 },
              onDragStart: this.onDragStart,
              onDragEnd: this.onDragEnd,
              resetInitialPos: false
            }
          },
          blue: {
            draggableValue: {
              initialPosition: { left: 0, top:0 },
              onDragStart: this.onDragStart,
              onDragEnd: this.onDragEnd,
              resetInitialPos: false
            }
          },
          white: {
            draggableValue: {
              initialPosition: { left: 0, top:0 },
              onDragStart: this.onDragStart,
              onDragEnd: this.onDragEnd,
              resetInitialPos: false
            }
          },
          yellow: {
            draggableValue: {
              initialPosition: { left: 0, top:0 },
              onDragStart: this.onDragStart,
              onDragEnd: this.onDragEnd,
              resetInitialPos: false
            }
          },
        }
      }
    },
    methods: {
      rollDice() {
        if (this.game.dice.dice.rolling === true) {
          return
        }

        this.game.dice.dice.rolling = true
        this.updateDice()
        setTimeout(() => {
          this.game.dice.dice.value = Math.floor(Math.random() * 6) + 1
          this.game.dice.dice.rolling = false
          this.updateDice()
        }, 1000)

      },
      showQuestion(colour) {
        this.game.questions[colour].current = this.game.questions[colour].next
        this.game.questions[colour].next = this.game.questions[colour].next >= this.game.questions[colour].last
          ? 1
          : this.game.questions[colour].next + 1
        $(`#${colour}Modal`).modal('show')
        this.updateQuestions(colour)
      },
      backHome (pawn) {
        this.pawns[pawn].draggableValue.resetInitialPos = true
      },
      onDragStart (positionDiff, absolutePosition, event) {
        this.pawns[event.target.id].draggableValue.resetInitialPos=false;
      },
      onDragEnd (positionDiff, absolutePosition, event) {
        this.updatePawn(positionDiff, absolutePosition, event)
      },
      updatePawn(positionDiff, absolutePosition, event) {
        this.sync({
            element: 'pawns',
            reference: event.target.id,
            value: {top: absolutePosition.top, left: absolutePosition.left}
          })
      },
      updateDice() {
        this.sync({
          element: 'dice',
          reference: 'dice',
          value: this.game.dice.dice
        })
      },
      updateQuestions(question) {
        this.sync({
          element: 'questions',
          reference: question,
          value: this.game.questions[question]
        })
      },
      sync(data) {
        axios.post(`/api/games/${this.game.id}/state`, data)
          .then((response) => {
            this.hydrateGame(response.data)
          })
      },
      reset() {
        this.$confirm(
          {
            message: `Weet je zeker dat je opnieuw wil beginnen?`,
            button: {
              no: 'Nee',
              yes: 'Ja'
            },
            /**
             * Callback Function
             * @param {Boolean} confirm
             */
            callback: confirm => {
              if (confirm) {
                axios.post(`/api/games/${this.game.id}/reset`)
                  .then((response) => {
                    this.hydrateGame(response.data)
                  })

              }
            }
          }
        )
      },
      hydrateGame(game) {
        this.game = Object.assign({}, game)
        this.updateLocalState()
      },
      getGame() {
        axios.get(`/api/games/${this.game.id}`)
          .then((response) => {
            this.hydrateGame(response.data)
          })
      },
      updateLocalState() {
        this.updatePawnPositions()
        this.updateCardModals()
      },
      updatePawnPositions() {
        ['black', 'red', 'green', 'blue', 'white', 'yellow'].forEach((pawn) => {
          if (this.pawns[pawn].draggableValue.initialPosition.top !== this.game.pawns[pawn].top) {
            this.pawns[pawn].draggableValue.initialPosition = this.game.pawns[pawn]
            this.pawns[pawn].draggableValue.resetInitialPos =true
          }
        })
      },
      updateCardModals() {
        ['green','orange'].forEach((colour) => {
          this.game.questions[colour].current
            ? $(`#${colour}Modal`).modal('show')
            : $(`#${colour}Modal`).modal('hide')
        })
      },
      copyLink() {
          let copyText = document.getElementById("gameLink");
          copyText.select();
          copyText.setSelectionRange(0, 99999);
          document.execCommand("copy");
          this.copied = true
          setTimeout(() => {
            this.copied = false
          }, 2000)

          // alert("Copied the text: " + copyText.value);
      }
    },
  }
</script>

<style>
  table tr td {
    padding: 0;
  }

  .game-board {
    background: url('/images/board.png');
    background-size: cover;
    width: 1119px;
    height: 791px;
  }

  .hand {
    cursor: pointer;
  }

  .pawn {
    width: 25px;
    height: auto;
    cursor: grab;
  }

  .dice {
    margin: 30px auto;
    background: url('/images/dice.png');
    width:80px;
    height:80px;
    border-radius: 5px;
  }

  .dice-1, .dice-2, .dice-3 {
    background-position-y: -4px;
    background-position-x: -9px;
  }

  .dice-2 {
    background-position-x: -90px;
  }

  .dice-3 {
    background-position-x: -172px;
  }

  .dice-4, .dice-5, .dice-6 {
    background-position-y: 84px;
    background-position-x: -9px;
  }

  .dice-5 {
    background-position-x: -90px;
  }

  .dice-6 {
    background-position-x: -172px;
  }

  .dice.rolling {
    opacity: 0.25;
  }

  .home {
    background: #CCC;
  }

  .home > table tr > td:first-child {
    padding: 20px;
  }

  .home > table .card-img img{
    width:250px;
  }
</style>