<template>
  <div class="container">
      <div class="col-4 my-5 mx-auto">
        <div class="card">
          <div class="card-header">
            SMZ: Samen voor de goede beoordeling
          </div>
          <div v-if="id" class="card-body text-center">
            <h5>Game ID</h5>
            <p><small>{{ id }}</small></p>
            <div class="form-group">
              <button class="btn btn-success" @click="join">Join game</button>
              <a href="/" class="btn btn-secondary">New game</a>
            </div>
          </div>
          <div v-else class="card-body">
            <div class="form-group">
              <label for="typeOfGame">Type of game</label>
              <select v-model="form.data.type" class="form-control" id="typeOfGame">
                <option value="employee">Employee</option>
                <option value="management">Management</option>
              </select>
            </div>
            <div class="form-group">
              <button class="btn btn-primary" @click="store">Create new game</button>
            </div>
          </div>
          <div v-if="id" class="card-footer text-center">
            <div class="form-group">
              <textarea id="gameLink" rows="3" class="form-control small border-0 bg-transparent" :value="link"></textarea>
            </div>
            <div class="form-group">
              <button class="btn btn-secondary btn-block" @click="copyLink">Copy game link</button>
              {{ copied ? 'copied' : '' }}

            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
  export default {
    props: {
      initialId: {
        type: String,
        required: false,
      },
      baseLink: {
        type: String,
        required: true
      }
    },
    created() {
      this.id = this.initialId
    },
    computed: {
      link () {
        return this.id ? `${this.baseLink}/join/${this.id}` : null
      }
    },
    data () {
      return {
        id: null,
        copied: false,
        form: {
          data: {
            type: 'employee'
          }
        }
      }
    },
    methods: {
      join() {
        window.open(`/games/${this.id}`,`game${this.id}`,'width=1455,height=940');
      },
      store() {
        axios.post(`/api/games/`, this.form.data)
          .then((response) => {
            window.location.href=`/join/${response.data.id}`
          })
      },
      copyLink() {
        let copyText = document.getElementById("gameLink");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");
        this.copied = true
        setTimeout(() => {
          this.copied = false
        }, 2000)
      }
    }
  }
</script>

<style>

</style>