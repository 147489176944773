require('./bootstrap');
window.Vue = require('vue');
import VueConfirmDialog from 'vue-confirm-dialog'
Vue.use(VueConfirmDialog)

Vue.component('welcome', require('./components/Welcome.vue').default);
Vue.component('game', require('./components/Game.vue').default);
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

const app = new Vue({
  el: '#app',
  data : {
  }
});